import { ref, reactive } from "vue";

const waitingPackageData = ref([])
const scannedItem = ref([])
const selectedItem = ref({})
const state = reactive({
  reprintID: "",
  reprintTrackingCode:"",
  searchText: "",
  searchedColumn: "",
  selectedRowKeys: [],
  // Check here to configure the default column
  loading: false,
  modalShowPrintLabel : false,
  modalShowRMADetail : false,
  currentShowRMA : '',
  searchInput : '',
  modalShowPreparePackage : false,
  labelUrl:"",
  packageUrl:"",
  showPrintAction: false,
  onRmaEditMode:false
});


export default function dataState() { 
  
  const setSelectedItem = (data) => { 
    state.modalShowRMADetail = true
    selectedItem.value = data
  }
   
  return {
    setSelectedItem,
    selectedItem,
    scannedItem,
    waitingPackageData,
    state 
  };
}
